import Downshift from "downshift";
import classNames from "classnames";
import React from "react";
import { findDietTagImg } from "../helpers/singleOfferHelpers";

export const Select = ({
  dietUrl,
  disabled,
  items,
  label,
  placeholder,
  selectedItem,
  selectedItemKey,
  selectItem,
  selectLink,
  containerClass,
  id,
  smallHeight,
    showSelect
}) => {
  return (
    <>
      <Downshift
        id={id}
        itemToString={(item) => (item ? item[selectedItemKey] : "")}
        onChange={(item) => selectItem(item)}
      >
        {({ getItemProps, getToggleButtonProps, isOpen }) => (
          <div className={classNames("menu-widget-select", containerClass)}>
            {label && (
              <p className="menu-widget-select__label">{label}</p>
            )}
            <div
              className={classNames("menu-widget-select__button label-m", {
                "menu-widget-select__button--link": selectLink,
                "menu-widget-select__button--small": smallHeight,
                "menu-widget-select__button--hover": showSelect,
              })}
              {...getToggleButtonProps()}
            >
              <span
                className={classNames({
                  "menu-widget-select__selectedItem--link": selectLink,
                  "menu-widget-select__selectedItem": !dietUrl,
                  "menu-widget-select__selectedItemWithImg": dietUrl,
                })}
              >
                {(() => {
                  if (selectedItem && Object.keys(selectedItem).length > 0) {
                    if (dietUrl) {
                      return (
                        <span className="menu-widget-select__selectedItemWithImg-element" >
                          <div className={"menu-widget-select__selectedItemWithImg-img"} style={{backgroundImage: `url(${selectedItem?.dietImageUrl ? selectedItem?.dietImageUrl : findDietTagImg(selectedItem?.dietTag) })`}} />
                          {selectedItem[selectedItemKey]}
                        </span>
                      );
                    }
                    return selectedItem[selectedItemKey];
                  }
                  if (placeholder) {
                    return placeholder;
                  }
                  return "Wybierz..";
                })()}
              </span>
              {!disabled && (
                <span
                  className={classNames({
                    "menu-widget-select__selectedItem-icon-select-link":
                      selectLink || selectedItem,
                  })}
                >
                  <div className={"menu-widget-catering-menu-bar-with-select-on-desktop-icon--down"}>
                    {/*downChevron.svg*/}
                    <svg width="22.5" height="15" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9999 1.1697C10.8126 0.983448 10.5591 0.878906 10.2949 0.878906C10.0308 0.878906 9.77731 0.983448 9.58995 1.1697L5.99995 4.7097L2.45995 1.1697C2.27259 0.983448 2.01913 0.878906 1.75495 0.878906C1.49076 0.878906 1.23731 0.983448 1.04995 1.1697C0.95622 1.26266 0.881826 1.37326 0.831057 1.49512C0.780288 1.61698 0.75415 1.74769 0.75415 1.8797C0.75415 2.01171 0.780288 2.14242 0.831057 2.26428C0.881826 2.38613 0.95622 2.49674 1.04995 2.5897L5.28995 6.8297C5.38291 6.92343 5.49351 6.99782 5.61537 7.04859C5.73723 7.09936 5.86794 7.1255 5.99995 7.1255C6.13196 7.1255 6.26267 7.09936 6.38453 7.04859C6.50638 6.99782 6.61699 6.92343 6.70995 6.8297L10.9999 2.5897C11.0937 2.49674 11.1681 2.38613 11.2188 2.26428C11.2696 2.14242 11.2957 2.01171 11.2957 1.8797C11.2957 1.74769 11.2696 1.61698 11.2188 1.49512C11.1681 1.37326 11.0937 1.26266 10.9999 1.1697Z" fill="#62748C"/>
                    </svg>
                  </div>
                </span>
              )}
            </div>
            <div className="menu-widget-select__itemsWrapper">
              {isOpen && !disabled ? (
                <div className="menu-widget-select__downshiftDropdown">
                  {items.map((item, index) =>
                    dietUrl ? (
                      <div
                        className="menu-widget-select__dropdownItemWithUrl label-m"
                        key={index}
                        {...getItemProps({ key: index, index, item })}
                      >
                        {dietUrl && (
                          <img
                            alt={item?.dietName}
                            src={
                              item?.dietImageUrl
                                ? item?.dietImageUrl
                                : findDietTagImg(item?.dietTag)
                            }
                          />
                        )}
                        {item[selectedItemKey]}
                      </div>
                    ) : (
                      <div
                        className="menu-widget-select__dropdownItem label-m"
                        key={index}
                        {...getItemProps({ key: index, index, item })}
                      >
                        {item[selectedItemKey]}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </>
  );
};
