import React from "react";
import Modal from "react-modal";
//ta linijka dyskusyjna, do sprawdzenia czy działa podpinając się pod element 'root' czy muszę podpiąć się pod #__next
Modal.setAppElement(document.getElementById("root"));

export const CustomModal = ({
  children,
  isOpen,
  onRequestClose,
  style,
  className,
  overlayClassName,
}) => {
  return (
    <Modal
      className={className}
      closeTimeoutMS={300}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose && onRequestClose()}
      overlayClassName={overlayClassName}
      style={style}
    >
      {children}
    </Modal>
  );
};
