import React from "react";
import classNames from "classnames";

export const Button = ({
  colorBlank,
  colorClean,
  containerClass,
  icon,
  id,
  classes,
  ...restProps
}) => {
  return (
    <button
      className={classNames("menu-widget-button", containerClass, {
        "menu-widget-button__button-blank": colorBlank,
        "menu-widget-button__button-clean": colorClean,
      })}
      id={id}
      type="button"
      {...restProps}
    >
      {icon && (
        <div className={`menu-widget-button-icon ${classes}`} id={id}>
          {icon}
        </div>
      )}
    </button>
  );
};
