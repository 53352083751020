import config from "../../server.config";
const urlBase = `${config.server_url}/api/form/open`;

export const getServerApiMenu = (query, id, key) => {
  return fetch(`${urlBase}/menus?searchCriteria=${query}`, {
      headers: {
      "api-key": key,
      "company-id": id,
      }
  }).then((res) => res.json());
};

export const getServerApiDietDetails = (id, key) =>
  fetch(`${urlBase}/company-details/${id}/dietDetails`, {
      headers: {
          "api-key": key,
      "company-id": id,
      },
  })

export const getServerApiCompanyParams = (id, key) =>
  fetch(`${urlBase}/company-details/${id}/params`, {
      headers: {
      "api-key": key,
      "company-id": id,
      }
  });

