import React from "react";

export const SmallMenu = ({ color = " #8FA1B3" }) => {
  return (
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M20 69C18.8954 69 18 68.1046 18 67V13C18.0033 11.8968 18.8968 11.0033 20 11H60C61.1032 11.0033 61.9967 11.8968 62 13V67C62 68.1046 61.1046 69 60 69H20Z"
        fill="white"
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M22 16V19C22 21.2091 23.7909 23 26 23V16"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M30 16V19C30 21.2091 28.2091 23 26 23V37"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54 37V16C56.5064 17.433 58.0379 20.1131 58 23C58.0379 25.8869 56.5064 28.567 54 30"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M41 37C45.9706 37 50 32.9706 50 28C50 23.0294 45.9706 19 41 19C36.0294 19 32 23.0294 32 28C32 32.9706 36.0294 37 41 37Z"
        fill="white"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M36 28C36.0033 25.2399 38.2399 23.0033 41 23"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M46 28C45.9967 30.7601 43.7601 32.9967 41 33"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54 44H47"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M43 44H26"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54 49H44"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M40 49H26"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54 54H48"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M44 54H26"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M54 59H46"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M42 59H26"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
