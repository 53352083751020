import { DateTime } from "luxon";

export const createArray = (number) => [...Array(number).keys()];
export const customNumSort = (key) => (array) => {
  if (!array) {
    return [];
  }
  return [...array]?.sort((prev, next) => prev[key] - next[key]);
};
export const getInitialDatesArray = () => {
  const yesterday = DateTime.local().minus({ days: 7 });
  return createArray(10).map((day) =>
    yesterday.plus({ days: day }).toISODate()
  );
};
export const customFind = (key, element) => (array) =>
  array?.find((item) => item[key] === element);
