import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./app.scss";
import "./style.scss";

const target = document.getElementById("root-widget-menu");
if (target) {
  const config = JSON.parse(target.getAttribute("data-config"));
  ReactDOM.render(<App config={config} />, target);
}
