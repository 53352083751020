import { DateTime } from "luxon";

export const checkIfHighlightedDateIsSecondToLast = (
  highlightedDate,
  dates
) => {
  const index = dates?.findIndex((date) => date === highlightedDate);
  return index === 1;
};
export const getNewFetchDate = (direction, dates) => {
  const lastDay = dates?.slice(-1)[0];
  const firstDay = dates[0];
  if (direction === "up") {
    return DateTime.fromISO(lastDay).plus({ day: 1 }).toISODate();
  }
  return DateTime.fromISO(firstDay).minus({ day: 1 }).toISODate();
};
export const getNewHighlightedDate = (highlightedDate, direction) => {
  const dateTime = DateTime.fromISO(highlightedDate);
  if (direction === "up") {
    return dateTime.plus({ day: 1 }).toISODate();
  }
  return dateTime.minus({ day: 1 }).toISODate();
};
export const getQuery = (selectedDiet, dates) => {
  const menusObject = {
    dietId: selectedDiet?.dietId,
    dates,
  };
  return encodeURIComponent(JSON.stringify(menusObject));
};
export const newChangeDates = (direction, dates) => {
  if (!dates) {
    return null;
  }
  const lastDay = dates?.slice(-1)[0];
  const firstDay = dates[0];

  if (direction === "up") {
    const extraDay = DateTime.fromISO(lastDay).plus({ day: 1 }).toISODate();
    return [...dates, extraDay];
  }
  const extraDay = DateTime.fromISO(firstDay).minus({ day: 1 }).toISODate();
  return [extraDay, ...dates];
};
export const parseDay = (format, day) => {
  if (!day) {
    return "";
  }
  const luxonDay = DateTime.fromISO(day);
  return luxonDay.setLocale("pl").toFormat(format);
};
export const getNewIndex = (direction, currentIndex, length) => {
  if (direction === "up") {
    if (currentIndex + 1 === length) {
      return 0;
    }
    return currentIndex + 1;
  }
  if (currentIndex === 0) {
    return length - 1;
  }
  return currentIndex - 1;
};
export const findSelectedDietIndex = (selectedDiet, diets) => {
  if (!selectedDiet || !diets) {
    return 0;
  }
  return diets?.findIndex((diet) => diet?.dietId === selectedDiet?.dietId);
};
